import { type onboardingSubtypes, sendEvent, type sendEventProps } from '@repo/utils';

/**
 * Sends an event with the specified type and data to New Relic for the
 * onboarding app.
 *
 * @param {event} event - Object containing the type of event and the data to be
 *                       sent.
 */
export const sendOnbEvent = async (
  event: Omit<sendEventProps, 'app' | 'instanceType'> & { subtype?: onboardingSubtypes },
) => {
  sendEvent({
    app: 'onboarding',
    instanceType: process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE as sendEventProps['instanceType'],
    ...event,
  });
};
