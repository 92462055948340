'use client';
import Loading from '@/app/(withLayout)/loading';
import { OnbForm } from '@/components/form';
import { sendOnbEvent } from '@/components/newRelic';
import { connectedField } from '@/redux/fields';
import { pushStepVariables } from '@/redux/flowSlice';
import { showModal } from '@/redux/navigationSlice';
import { RootState } from '@/redux/store';
import { Button, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { customerRegistration, installment, repaymentTerms } from '@repo/onb-api';
import { MaterialIcon, OrderCard, RadioGroup } from '@repo/ui';
import { deleteClientCookie, formatter, getClientCookie, parseJwt } from '@repo/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StepWrapper from './stepWrapper';

export default function RepaymentTermsPayments({
  stepname: stepName,
  stepdata: stepData,
}: {
  stepname: repaymentTerms['name'];
  stepdata?: repaymentTerms['variables'];
}): JSX.Element {
  const router = useRouter();
  const [paymentEdit, setPaymentEdit] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<installment>();
  const dispatch = useDispatch();
  const orderData = parseJwt(getClientCookie('tokenData') || '') as getOrderCredentialJWT;
  const [sortedInstallments, setSortedInstallments] = useState<installment[]>();
  if (!orderData) {
    deleteClientCookie('instanceId');
    router.push('/');
  }

  const { merchantName, price, merchantOrderId } = orderData;

  const { variables = {} } = useSelector((state: RootState) => state.flowdata);
  const { firstName = '' } = variables;

  useEffect(() => {
    setSortedInstallments([...(stepData?.installments || [])].sort((a, b) => a.count - b.count));
  }, [stepData?.installments]);

  useEffect(() => {
    if (!stepData?.installmentPlanId) {
      dispatch(
        pushStepVariables({
          stepName,
          variable: 'installmentPlanId',
          value: sortedInstallments?.[sortedInstallments?.length - 1]?.planId,
        }),
      );
    }
    if (!stepData?.requestedAmount) {
      dispatch(
        pushStepVariables({
          stepName,
          variable: 'requestedAmount',
          value: parseFloat(price) || 0,
        }),
      );
    }
  }, [sortedInstallments, stepData?.installmentPlanId, dispatch]);
  useEffect(() => {
    if (selectedPlan?.planId !== stepData?.installmentPlanId) {
      setSelectedPlan(sortedInstallments?.find(({ planId }) => planId === stepData?.installmentPlanId));
    }
  }, [sortedInstallments, stepData?.installmentPlanId]);
  useEffect(() => {
    if (sortedInstallments?.length === 0) {
      sendOnbEvent({ type: 'error', subtype: 'noInstallments', data: { stepName }, severity: 'high' });
      router.push('/error');
    }
  }, [sortedInstallments, stepName, router]);

  return (stepData?.installments?.length || 0) === 0 ? (
    <Loading />
  ) : (
    <StepWrapper>
      <Typography variant="h1">¡Buenas noticias!</Typography>
      <Typography variant="h2">Pagarás de la siguiente forma:</Typography>

      <OnbForm<customerRegistration> stepName={stepName!}>
        <OrderCard merchantName={merchantName} merchantOrderId={merchantOrderId} price={price} />
        <Grid
          container
          direction={'column'}
          sx={{
            width: '100%',
            p: 2,
            borderRadius: 2,
            backgroundColor: 'background.paper',
            border: '1px solid',
            borderColor: 'primary.main',
          }}
        >
          <Collapse in={!paymentEdit}>
            <Grid container direction={'column'} gap={1}>
              <Grid container direction={'row'} gap={1} alignItems="center" justifyContent={'space-between'}>
                <Typography variant="h2" fontSize={18} fontWeight={'500'}>
                  Tu compra en cuotas
                </Typography>
                <Tooltip title={'Editar cuotas'}>
                  <IconButton onClick={() => setPaymentEdit(true)}>
                    <MaterialIcon color="primary" icon="edit" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid container direction={'row'} gap={1} alignItems="center">
                <Typography
                  component={'span'}
                  variant="h1"
                  color="primary.main"
                >{`${selectedPlan?.count} cuotas`}</Typography>
                {!selectedPlan?.withInterest && (
                  <Typography variant="body2" color="primary.main">
                    Sin Interés
                  </Typography>
                )}
              </Grid>
              <Grid container direction={'row'} gap={1} alignItems="center">
                <Typography
                  component={'span'}
                  variant="h1"
                  color="primary.main"
                >{`${formatter(selectedPlan?.amount?.toString(), 'currency')}`}</Typography>
                <Typography variant="body2" color="primary.main">
                  por mes
                </Typography>
              </Grid>
              <Typography variant="subtitle2">
                Total a pagar: {formatter(selectedPlan?.finalAmount, 'currency')}
              </Typography>
            </Grid>
          </Collapse>
          <Collapse in={paymentEdit}>
            <Grid>
              <Grid container direction={'row'} gap={1} alignItems="center" justifyContent={'space-between'}>
                <Typography variant="h2" fontSize={18} fontWeight={'500'}>
                  Seleccioná un plan
                </Typography>
                <Tooltip title={'Volver'}>
                  <IconButton onClick={() => setPaymentEdit(false)}>
                    <MaterialIcon color="primary" icon="arrow_back" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <RadioGroup
                aria-labelledby="gender-label"
                data-testid="gender"
                options={sortedInstallments?.map(({ amount, count, planId, withInterest, finalAmount }) => ({
                  label: (
                    <Grid
                      container
                      direction={'row'}
                      alignItems="center"
                      justifyContent={'space-between'}
                      width={'100%'}
                    >
                      <Typography variant="body2">
                        {count} x {formatter(amount?.toString(), 'currency')}
                      </Typography>
                      <Typography variant="subtitle2">
                        {!withInterest ? 'sin interés' : formatter(finalAmount?.toString(), 'currency')}
                      </Typography>
                    </Grid>
                  ),
                  value: planId,
                }))}
                {...connectedField({
                  stepName,
                  name: 'installmentPlanId',
                  required: true,
                  beforeChange: (value) => parseInt(value),
                })}
              />
            </Grid>
          </Collapse>
        </Grid>
        <Button variant="text" onClick={() => dispatch(showModal({ body: 'selectedPlan', title: 'Plan de pagos' }))}>
          Ver plan de pagos
        </Button>
        <Grid container direction={'column'} gap={2} sx={{ width: '100%', mb: 2 }}>
          {selectedPlan?.planType === 'UPFRONT_PAYMENT' ? (
            <>
              <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
                <MaterialIcon color="primary" containerType="circle" icon="calendar_month" />
                <Typography variant="body2" color={'secondary.main'}>
                  Pagás la primera cuota hoy
                </Typography>
              </Grid>
              <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
                <MaterialIcon color="primary" containerType="circle" icon="credit_card" />
                <Typography variant="body2" color={'secondary.main'}>
                  Pagás con tarjeta de débito
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid container sx={{ gap: 3 }} direction={'row'} wrap="nowrap" alignItems={'center'}>
              <MaterialIcon color="primary" containerType="circle" icon="calendar_month" />
              <Typography variant="body2" color={'secondary.main'}>
                Pagás la primera cuota el {formatter(selectedPlan?.firstDueDate, 'date')}
              </Typography>
            </Grid>
          )}
        </Grid>
      </OnbForm>
    </StepWrapper>
  );
}
