'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { TextField, Typography } from '@mui/material';
import { getOrderCredentialJWT } from '@repo/ecommerce-api';
import { customerIdentification } from '@repo/onb-api';
import { RadioGroup } from '@repo/ui';
import { formatter, getClientCookie, parseJwt } from '@repo/utils';
import StepWrapper from './stepWrapper';

export default function CustomerIdentification({
  stepname: stepName,
}: {
  stepname?: customerIdentification['name'];
}): JSX.Element {
  const orderData = parseJwt(getClientCookie('tokenData') || '') as getOrderCredentialJWT;
  return (
    <StepWrapper>
      <Typography variant="h1">
        {process.env.NEXT_PUBLIC_ONB_INSTANCE_TYPE === 'onb-cuotas'
          ? '¡Comprá en cuotas ahora!'
          : '¡Sacá tu préstamo ahora!'}
      </Typography>
      <Typography variant="h2">Empecemos: ingresá tus datos y encontrá la opción perfecta para vos.</Typography>
      <OnbForm<customerIdentification> stepName={stepName!}>
        <TextField
          label="Ingresá tu DNI"
          autoFocus
          placeholder="Ej: 12345678"
          slotProps={{ htmlInput: { maxLength: 10, inputMode: 'numeric', 'data-testid': 'documentNumber' } }}
          disabled={!!orderData?.documentNumber}
          {...connectedField({
            stepName,
            name: 'documentNumber',
            required: true,
            defaultValue: orderData?.documentNumber ? orderData?.documentNumber : undefined,
            beforeRender: (value) =>
              orderData?.documentNumber ? formatter(orderData?.documentNumber, 'dni') : formatter(value, 'dni'),
            beforeChange: (value) =>
              orderData?.documentNumber ? orderData?.documentNumber : value.replaceAll(/\D/g, '').slice(0, 8),
            errorHandler: {
              type: 'text',
              errorText: 'Ingresa un DNI válido',
              validate: (value) => (orderData?.documentNumber ? true : value.length > 6),
            },
          })}
        />
        <RadioGroup
          aria-labelledby="gender-label"
          data-testid="gender"
          label="Seleccioná tu género"
          options={[
            { value: 'FEMALE', label: 'Femenino' },
            { value: 'MALE', label: 'Masculino' },
          ]}
          {...connectedField({
            stepName,
            name: 'gender',
            required: true,
            beforeChange: (value) => (value === 'MALE' ? 'MALE' : 'FEMALE'),
            errorHandler: {
              type: 'radioGroup',
              errorText: 'Este campo es requerido',
            },
          })}
        />
      </OnbForm>
    </StepWrapper>
  );
}
