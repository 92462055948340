'use client';
import { OnbForm } from '@/components/form';
import { connectedField } from '@/redux/fields';
import { pushStepVariables } from '@/redux/flowSlice';
import { FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { loanAmountAndPurpose } from '@repo/onb-api';
import { formatter } from '@repo/utils';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import StepWrapper from './stepWrapper';

type LoanPurposeType =
  | 'Arreglos del hogar'
  | 'Comprar / arreglar vehículo'
  | 'Pagar deudas'
  | 'Viajes'
  | 'Salud'
  | 'Otros';

export default function LoanAmountAndPurpose({
  stepname: stepName,
}: {
  stepname: loanAmountAndPurpose['name'];
}): JSX.Element {
  const dispatch = useDispatch();
  const [loanPurpose, setLoanPurpose] = useState<LoanPurposeType | undefined>(undefined);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoanPurpose(event.target.value as LoanPurposeType);
  };

  useEffect(() => {
    if (loanPurpose) {
      dispatch(
        pushStepVariables({
          stepName,
          variable: 'loanPurposes',
          value: [loanPurpose],
        }),
      );
    }
  }, [loanPurpose, dispatch]);

  return (
    <StepWrapper>
      <Typography variant="h1">Queremos saber más de vos.</Typography>
      <OnbForm<loanAmountAndPurpose> stepName={stepName!}>
        <TextField
          label="¿Cuánto necesitás?"
          autoFocus
          placeholder="Ej: 200000"
          slotProps={{ htmlInput: { maxLength: 12, inputMode: 'numeric', 'data-testid': 'desiredAmount' } }}
          {...connectedField({
            stepName,
            name: 'desiredAmount',
            required: true,
            beforeRender: (value) => formatter(value, 'currency'),
            beforeChange: (value) => Number(value.replaceAll(/\D+/g, '')),
            errorHandler: {
              type: 'text',
              errorText: 'Ingresa un monto válido',
              validate: (value) => value.length > 6,
            },
          })}
        />
        <FormGroup
          aria-labelledby="loanPurposes-label"
          data-testid="loanPurposes"
          sx={{
            gap: 1,
            '& >label:nth-last-of-type(1)': {
              margin: '0px',
            },
          }}
        >
          <FormLabel id="loanPurposes-label">¿En qué lo vas a usar?</FormLabel>
          <RadioGroup name="loanPurpose" value={loanPurpose} onChange={handleChange}>
            <FormControlLabel
              value="Comprar / arreglar vehículo"
              control={<Radio />}
              label="Comprar / arreglar vehículo"
            />
            <FormControlLabel value="Arreglos del hogar" control={<Radio />} label="Arreglos del hogar" />
            <FormControlLabel value="Pagar deudas" control={<Radio />} label="Pagar deudas" />
            <FormControlLabel value="Viajes" control={<Radio />} label="Viajes" />
            <FormControlLabel value="Salud" control={<Radio />} label="Salud" />
            <FormControlLabel value="Otros" control={<Radio />} label="Otros" />
          </RadioGroup>
        </FormGroup>
      </OnbForm>
    </StepWrapper>
  );
}
